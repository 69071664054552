@font-face {
  font-family: LeMurmure;
  src: url(./fonts/le-murmure.otf) format("opentype");
}

@font-face {
  font-family: HKGrotesk-Regular;
  src: url(./fonts/HKGrotesk-Regular.otf) format("opentype");
}

@font-face {
  font-family: HKGrotesk-Bold;
  src: url(./fonts/HKGrotesk-Bold.otf) format("opentype");
}

@font-face {
  font-family: MinionPro;
  src: url(./fonts/MinionPro-Regular.otf) format("opentype");
}

html,
body,
#root,
.app,
.content,
main,
.welcome {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
  background-color: #f9c6a2;
  color: #163d3c;
}

body.dark {
  background-color: #123231;
  color: #f9c6a2;
  transition: background-color ease-in 1s, color ease-in 1s;
}

a {
  border-bottom: 1px solid #163d3c;
  color: inherit;
  text-decoration: none;
}

a:hover{
  /*cursor: url("/img/icons/hand_cursor.svg"), url("/img/icons/hand_cursor.cur"),
    pointer; */
  cursor: pointer;
  text-decoration: underline;
}

h1 {
  margin: 0;
}

.welcome {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
}

.top-marquee {
  width: 100%;
  white-space: nowrap;
  align-self: flex-start;
  color: #f9c6a2;
  background-color: #123231;
  font-size: 40px;
  padding: 10px 0;
  box-sizing: border-box;
  font-family: LeMurmure, serif;
  transition: background-color ease-in 1s, color ease-in 1s;
}

body.dark .top-marquee {
  transition: background-color ease-in 1s, color ease-in 1s;
  background-color: #e84f35;
}

.LogoFontWrapper {
  display: block;
  text-align: center;
  width: 100%;
  align-self: flex-start;
}

.contactintro,
.welcomeintro {
  align-self: flex-start;
  margin-bottom: 0px;
}

.contactintro {
  text-align: left;
}

.welcome .LogoFont {
  display: none;
  font-size: 25vw;
  line-height: 1em;
  margin-top: 65px;
  position: relative;
}

.welcome .LogoFont,
.welcome .LogoFont2 {
  font-family: LeMurmure, serif;
  color: #e84f35;
  text-transform: uppercase;
  font-weight: 400;
}

.welcome .LogoFont2 {
  display: inline-block;
  font-size: 70vw;
  margin-top: 80px;
  line-height: 0.6em;
}

.welcome p,
a {
  font-family: HKGrotesk-Regular, sans-serif;
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0;
  line-height: 1.5;
}

.footer {
  width: 93vw;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  align-self: auto;
  margin-bottom: 20px;
}

.LogoFont img {
  height: 290px;
  width: 210px;
  position: absolute;
}

.LogossPanel {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.social-network:last-child .comma {
  display: none;
}

/* .social-network a {
  border-bottom: 1px solid #163d3c;
} */

.dark .social-network a {
  border-bottom-color: #f9c6a2;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }

  .welcome p,
  a {
    font-size: 21px;
  }
}

@media (min-width: 767px) {
  a {
    border-bottom: none;
  }

  .top-marquee {
    padding: 20px 0;
    font-size: 65px;
  }

  .LogoFontWrapper.with-margin {
    align-self: center;
  }

  .LogoFontWrapper.with-margin {
    margin-top: 80px;
  }

  .welcome .LogoFont {
    display: inline-block;
  }

  .welcome .LogoFont2 {
    display: none;
  }

  .welcome p,
  a {
    font-size: 28px;
    line-height: 1.5;
    text-align: left;
  }

  .contactintro,
  .welcomeintro {
    align-self: flex-end;
    margin-bottom: 32px;
  }

  .contactintro p {
    float: right;
  }

  .welcomeintro {
    margin-right: 50px;
  }

  .footer {
    margin-bottom: 0px;
    align-self: flex-end;
  }
}